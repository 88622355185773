<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="80%">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row class="twice">
        <el-form-item label="车牌号" prop="carNo">
          <el-input v-model="dataInfo.carNo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="违章编号" prop="illegalCode">
          <el-input v-model="dataInfo.illegalCode" autocomplete="off" placeholder="例如:3201211022135041"></el-input>
        </el-form-item>
        <el-form-item label="违章时间" prop="illegalTime">
          <el-input v-model="dataInfo.illegalTime" autocomplete="off" placeholder="例如:2019-08-11 13:04"></el-input>
        </el-form-item>
        <el-form-item label="罚款" prop="illegalMoney">
          <el-input v-model="dataInfo.illegalMoney" autocomplete="off">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="扣分" prop="illegalFen">
          <el-input v-model="dataInfo.illegalFen" autocomplete="off">
            <span slot="suffix">分</span>
          </el-input>
        </el-form-item>
        <el-form-item label="处理机关" prop="illegalOrg">
          <el-input v-model="dataInfo.illegalOrg" autocomplete="off" placeholder="例如:南京市公安局江宁分局交通警察大队"></el-input>
        </el-form-item>
        <el-form-item label="违章地点" prop="illegalAddress">
          <el-input v-model="dataInfo.illegalAddress" autocomplete="off" placeholder="例如:华为路至安德门大街"></el-input>
        </el-form-item>
        <el-form-item label="违章行为" prop="illegalContent">
          <el-input type="textarea" v-model="dataInfo.illegalContent" autocomplete="off" placeholder="例如:机动车违反规定停放、临时停车妨碍其他车辆、行人通行的，驾驶人不在现场的"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="comment">
          <el-input type="textarea" v-model="dataInfo.comment" autocomplete="off"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      activeName: 'base',
      dataRules: {
        roomName: [{ required: true, trigger: 'blur', message: '会议室名称不能为空' }],
        place: [{ required: true, trigger: 'blur', message: '会议室位置不能为空' }]
      }
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    uploaded(res) {
      Object.assign(this.dataInfo, {
        imgUrlList: res
      })
      // this.$set(this.dataInfo)
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/car/illegal/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
