<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="处理状态">
          <el-radio-group v-model="condition.dealState" @change="getPageData()">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="true">已处理</el-radio-button>
            <el-radio-button label="false">未处理</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="车牌号/违章内容/违章地点"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="dealState" slot-scope="scope">
          <span v-if="scope.row.dealState" class="text-green">已处理</span>
          <span v-if="!scope.row.dealState" class="text-red">未处理</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import illegalAddoredit from './illegal_addoredit'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarIllegal',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '决定书编号', prop: 'illegalCode' },
          { label: '车牌号', prop: 'carNo' },
          { label: '违章时间', prop: 'illegalTime', filter: (a, data) => this.$lgh.limitTo(a, 16) },
          { label: '罚款', prop: 'illegalMoney' },
          { label: '扣分', prop: 'illegalFen' },
          { label: '处理机关', prop: 'illegalOrg' },
          { label: '违章地点', prop: 'illegalAddress' },
          { label: '处理状态', slot: 'dealState' }
        ],
        operation: {
          width: '220',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增', click: this.add },
          buttons: [{ type: 'primary', icon: 'el-icon-edit', label: '修改', click: this.edit }]
        }
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        dealState: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/car/illegal/pagelist', ['enterpriseCode', this.condition])
    },
    async add() {
      await this.$lgh.openDialog({
        ins: illegalAddoredit,
        title: '新增违章信息',
        data: {
          departList: this.departList,
          roleList: this.roleList,
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode
          }
        }
      })
      this.$lgh.toastSuccess('新建违章信息成功')

      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: illegalAddoredit,
        title: '修改违章信息',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改违章信息成功')
      this.getPageData()
    }
  }
}
</script>
